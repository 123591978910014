import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./assets/css/custom.css";
import "./assets/css/responsive-pos.css";
import "./assets/css/custom-pos.css";
import "./assets/css/responsive.css";
import "react-image-crop/dist/ReactCrop.css";
import React from "react";
import {
  BrowserRouter,
  createBrowserRouter,
  Navigate,
  NavLink,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import MainLayout from "./components/layout/MainLayout";
import SignUp from "./components/users/SignUp";
import Verification from "./components/users/Verification";
import Login from "./components/users/Login";
import SellerLogin from "./components/sellers/SellerLogin";
import ForgotPassword from "./components/sellers/ForgotPassword";
import ResetPassword from "./components/sellers/ResetPassword";
import UpdatePassword from "./components/sellers/UpdatePassword";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoute from "./routes/PrivateRoute";
import ProductList from "./components/sellers/products/ProductList";
import AddProduct from "./components/sellers/products/AddProduct";
import ProductDetail from "./components/sellers/products/ProductDetail";
import SupplierList from "./components/sellers/purchase/SupplierList";
import AddSupplier from "./components/sellers/purchase/AddSupplier";
import WarehouseList from "./components/sellers/warehouse/WarehouseList";
import AddWarehouse from "./components/sellers/warehouse/AddWarehouse";
import PurchaseOrder from "./components/sellers/purchase/PurchaseOrder";
import PurchaseOrderList from "./components/sellers/purchase/PurchaseOrderList";
import PurchaseOrderDetail from "./components/sellers/purchase/PurchaseOrderDetail";
import Bill from "./components/sellers/purchase/Bill";
import InventorySummery from "./components/sellers/inventory/InventorySummery";
import NewFeaturee from "./components/sellers/products/newFeature/NewFeaturee";
import WelcomePage from "./components/common/WelcomePage";
// import PosLoginFrom from "./components/pos/PosLoginFrom";
import PosPrivate from "./PosComponent/PosPrivate";
import PosLoginFrom from "./PosComponent/PosLoginFrom";
import PosMainLayout from "./PosComponent/mainLayout/PosMainLayout";
import PosInitialUI from "./PosComponent/mainLayout/initial/PosInitialUI";
import BillingCheckOut from "./PosComponent/mainLayout/BillingCheckout/BillingCheckOut";

function App() {
  // const routingRules = createBrowserRouter([
  //   {
  //     path: "/",
  //     element: <WelcomePage />,
  //   },
  //   { path: "*", element: <h1>wrong path</h1> },
  // ]);
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<ProductList />}></Route>
            <Route path="/update-password" element={<UpdatePassword />}></Route>
            <Route
              path="/products/product-list"
              element={<ProductList />}
            ></Route>
            <Route
              path="/products/add-product"
              element={<AddProduct />}
            ></Route>

            <Route
              path="/products/product-detail/:id"
              element={<ProductDetail />}
            ></Route>
            <Route
              path="/purchase/supplier-list"
              element={<SupplierList />}
            ></Route>
            <Route
              path="/purchase/add-supplier"
              element={<AddSupplier />}
            ></Route>
            <Route
              path="/purchase/purchase-order-list"
              element={<PurchaseOrderList />}
            ></Route>
            <Route
              path="/purchase/purchase-order"
              element={<PurchaseOrder />}
            ></Route>
            <Route
              path="/purchase/purchase-order-detail/:id"
              element={<PurchaseOrderDetail />}
            ></Route>
            <Route path="/purchase/bill/:id" element={<Bill />}></Route>
            <Route
              path="/warehouse/warehouse-list"
              element={<WarehouseList />}
            ></Route>
            <Route
              path="/warehouse/add-warehouse"
              element={<AddWarehouse />}
            ></Route>
            <Route
              path="/inventory/inventory-summary"
              element={<InventorySummery />}
            ></Route>
            {/* <Route path="/pos/dashboard" element={<DashBoard />}></Route> */}
          </Route>
          <Route path="/seller-login" element={<SellerLogin />}></Route>
          <Route path="/forgot-password" element={<ForgotPassword />}></Route>
          <Route
            path="/reset-password/:token"
            element={<ResetPassword />}
          ></Route>
          <Route path="/choseuser" element={<WelcomePage />}></Route>
          <Route path="/pos" element={<PosLoginFrom />} />

          <Route path="/posContent" element={<PosPrivate />}>
            <Route path="/posContent" element={<PosMainLayout />}>
              <Route path="/posContent/" element={<PosInitialUI />}></Route>
              <Route
                path="/posContent/checkOut"
                element={<BillingCheckOut />}
              ></Route>

              <Route path="/posContent/one" element={<h3>one</h3>}></Route>
              <Route path="/posContent/two" element={<h3>two</h3>}></Route>
            </Route>
          </Route>
          <Route path="*" element={<Navigate to={"/"} />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
      {/* <RouterProvider router={routingRules}></RouterProvider> */}
    </>
  );
}

export default App;
