import React from "react";
import { NavLink } from "react-router-dom";

const Messege = () => {
  if (!localStorage.getItem("holdBill")) {
    localStorage.setItem("holdBill", JSON.stringify([]));
  }

  return (
    <div style={{ fontSize: "40px" }}>
      <div>WellCome ...</div>
      <NavLink
        to={"/seller-login"}
        onClick={() => {
          localStorage.setItem("user", "seller");
        }}
        className="btn btn-primary fs-1 w-100"
      >
        Login as a seller.
      </NavLink>
      <br />

      <NavLink
        to={"/pos"}
        onClick={() => {
          localStorage.setItem("user", "pos");
        }}
        className="btn btn-primary fs-1 w-100"
      >
        Login as a POS User.
      </NavLink>
    </div>
  );
};

const WelcomePage = () => {
  return (
    <div
      className="text-center d-flex justify-content-center align-items-center "
      style={{ height: "100vh" }}
    >
      <Messege />
    </div>
  );
};

export default WelcomePage;
