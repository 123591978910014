import { combineReducers, configureStore } from "@reduxjs/toolkit";
import appStateSlice from "./features/appStateSlice";
import regSlice from "./features/regSlice";
import loginSlice from "./features/loginSlice";
import sellerLoginSlice from "./features/sellers/sellerLoginSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import sellerProductSlice from "./features/sellers/sellerProductSlice";
import sellerPurchaseSlice from "./features/sellers/sellerPurchaseSlice";
import sellerWarehouseSlice from "./features/sellers/sellerWarehouseSlice";
import sellerInventorySlice from "./features/sellers/sellerInventorySlice";
import { Warehouse } from "@mui/icons-material";
import { WarehouseSlice } from "./features/WarehouseSlice";
import { EmailPassWord } from "./features/EmailPassWordSlice";
import ProductsSlice from "./posSlice/ProductsSlice";
import { SelectedCustomer } from "./posSlice/SelectedCustomer";
import BucketSlice from "./posSlice/BucketSlice";
import HoldOrderSlice from "./posSlice/HoldOrderSlice";
import { BucketMenegeSlice } from "./posSlice/BucketMenegeSlice";
import { HoldBucketMeneg } from "./posSlice/HoldBucketMeneg";



// const persistConfig = {
//   key: 'root',
//   storage,
//   version: 1,
// };
// const rootReducer = combineReducers({
//    appState: appStateSlice,
//     regUser: regSlice,
//     loginUser: loginSlice,
//     loginSeller: sellerLoginSlice
// });

// const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: {
    appState: appStateSlice,
    regUser: regSlice,
    loginUser: loginSlice,
    loginSeller: sellerLoginSlice,
    sellerProducts: sellerProductSlice,
    sellerPurchase: sellerPurchaseSlice,
    sellerWarehouse: sellerWarehouseSlice,
    sellerInventory: sellerInventorySlice,
    Warehouse:WarehouseSlice.reducer,
    PosDetail:ProductsSlice,
    TheCustomer:SelectedCustomer.reducer,
    AboutBucket:BucketSlice,
    aboutHoldOrder:HoldOrderSlice,
    BucketControl:BucketMenegeSlice.reducer,
    HoldBilling:HoldBucketMeneg.reducer
    // EmailPassWorddetails:EmailPassWord.reducer
  },
  // reducer: persistedReducer,
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware({ serializableCheck: false }),
});

// export type RootState = ReturnType<typeof store.getState>;
// export const persistor = persistStore(store);
