import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
// import { Seller_products } from "../../constants/Api/Api";
import { useParams } from "react-router-dom";
// import { Seller_products } from "../../../../constants/Api/Api";
import { Bounce, toast } from "react-toastify";
import { Seller_products } from "../../../constants/Api/Api";
import { Dialog } from "@mui/material";

const Variation = () => {
  const [title, settitle] = useState(["Price", "Color", "Weight"]);
  const [isedit, setisedt] = useState(false);
  const [createTitleName, setCreateTitleName] = useState("");
  const [val, setval] = useState([]);
  const { id } = useParams();
  const [vari, setvariant] = useState();
  const [edit, setedit] = useState(false);
  const [inputFields, setInputFields] = useState([
    { Save: "" },
    { "Upload Image": "" },
  ]);
  const [arr, setArr] = useState([
    // { Price: "565656", Weight: "250gm", Save: "", "Upload Image": "" },
    // { Price: "654654", Weight: "350gm", Save: "", "Upload Image": "" },
  ]);
  const [showForArr, setShowForArr] = useState(Array(arr.length).fill(false));

  const [showForInput, setShowForInput] = useState(false);

  const [opendilog, setopendilog] = useState(false);
  const [barcodesrc, setBarcodesrc] = useState("");

  const chngeFirstIndexData = (e, index) => {
    inputFields[index][e.target.name] = e.target.value;
  };

  const addField = () => {
    const combinedData = inputFields.reduce(
      (acc, obj) => ({ ...acc, ...obj }),
      {}
    );
    setArr([...arr, combinedData]);
  };

  const func1 = () => {
    let clsname = "";
    if (!showForInput) {
      clsname = "edit";
    } else {
      clsname = "disabled_btn";
    }
    // console.log(clsname, "clsname");
    return clsname;
  };

  const func2 = () => {
    let clsname = "";
    if (!showForInput) {
      clsname = "disabled_btn";
    } else {
      clsname = "edit";
    }
    // console.log(clsname, "clsname");
    return clsname;
  };
  const func3 = (objIndex) => {
    let clsname = "";
    if (!showForArr[objIndex]) {
      clsname = "edit";
    } else {
      clsname = "disabled_btn";
    }
    // console.log(clsname, "clsname");
    return clsname;
  };

  const func4 = (objIndex) => {
    let clsname = "";
    if (!showForArr[objIndex]) {
      clsname = "disabled_btn";
    } else {
      clsname = "edit";
    }
    // console.log(clsname, "clsname");
    return clsname;
  };
  const func5 = (par) => {
    let clsname = "";
    if (par) {
      clsname = "disabled_btn";
    } else {
      clsname = "edit";
    }

    return clsname;
  };

  const getTitle = (titleName) => {
    const result = inputFields
      .map((obj, index) => {
        return Object.keys(obj);
      })
      .flat();

    if (result.includes(titleName)) {
      toast.error("Already Exists", {
        className: "toast-message",
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        // theme: 'dark',
        transition: Bounce,
      });
    } else {
      const newModArr = [...inputFields];
      const inInd = newModArr.length - 2;
      newModArr.splice(inInd, 0, { [titleName]: "" });
      setInputFields(newModArr);

      /////////////////////////New Try//////////////////

      const addNew = arr.map((obj) => {
        const entries = Object.entries(obj);
        const getInd = entries.length - 2;
        const x = {
          [titleName]: "",
        };
        // entries.splice(getInd, 0, x);
        entries.splice(getInd, 0, Object.entries(x).flat());

        const newObject = Object.fromEntries(entries);
        return newObject;
      });

      setArr(addNew);
    }
  };

  useEffect(() => {
    axios
      .get(`${Seller_products}/${id}`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
      .then((res) => {
        //console.log(res.data.data.variants, "mainres");

        const mainres = [...res.data.data.variants];
        mainres.shift();

        for (const key in mainres) {
          setArr((pp) => {
            var nn = [...pp];
            nn.push({});
            for (const keyy in mainres[key].variant) {
              if (keyy !== "sku") {
                nn[key][`${keyy}`] = mainres[key].variant[keyy];
              }
            }
            nn[key]["Save"] = "";
            nn[key]["Upload Image"] = "";

            // console.log(nn, "test");
            return nn;
          });
        }

        for (const key in res.data.data.variants[0]?.variant) {
          setInputFields((pr) => {
            const update = [...pr];

            if (key !== "sku") {
              //   const ii = key.indexOf("_");
              //  if (ii) {
              //   update.splice(update.length - 2, 0, {
              //     [`${key}`]: res.data.data.variants[0]?.variant[`${key}`],
              //    });

              //  }else{

              //    update.splice(update.length - 2, 0, {
              //      [`${key}`]: res.data.data.variants[0]?.variant[`${key}`],
              //     });
              //   }
              // const chek = key.indexOf("_");
              if (key.indexOf("_") == -1) {
                update.splice(update.length - 2, 0, {
                  [`${key}`]: res.data.data.variants[0]?.variant[`${key}`],
                });
              } else {
                update.splice(update.length - 2, 0, {
                  [`${key.replace("_", " ")}`]:
                    res.data.data.variants[0]?.variant[`${key}`],
                });
              }

              // update.splice(update.length - 2, 0, {
              //   [`${key}`]: res.data.data.variants[0]?.variant[`${key}`],
              // });
            }
            return [...update];
          });
        }

        // for (const key in res.data.data.variants) {
        //   setval((pp) => {
        //     var nn = [...pp];
        //     nn.push({
        //       data: [],
        //       id: res?.data.data._id,
        //       prId: res?.data.data,
        //     });
        //     for (const keyy in res.data.data.variants[key].variant) {
        //       if (keyy !== "sku") {
        //         nn[key].data.push({
        //           [`${keyy}`]: res.data.data.variants[key].variant[keyy],
        //         });
        //       }
        //     }

        //     return nn;
        //   });
        // }
      });
  }, []);

  return (
    <>
      <div className="title-bx" id="price_tab">
        <div className="outr_title">
          <ul id="list" className="listitem">
            {title &&
              title.map((t, i) => (
                <div key={i}>
                  <li
                    className="litext"
                    value={t}
                    onClick={() => {
                      return getTitle(t);
                    }}
                  >
                    {t}
                  </li>
                </div>
              ))}
          </ul>
          <button
            type="button"
            className="add_title tab_lst_add"
            onClick={() => {
              setisedt(true);
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
        <div
          className={`${isedit ? "title_add_outr" : "title_add_outr hidden"}`}
        >
          <form>
            <input
              required
              type="text"
              // defaultValue=""
              value={createTitleName}
              className="input-field"
              onChange={(e) => {
                const inputVal = e.target.value;
                const capitalizedVal =
                  inputVal.charAt(0).toUpperCase() +
                  inputVal.slice(1).toLocaleLowerCase();
                setCreateTitleName(capitalizedVal);
              }}
            />
            <button
              type="button"
              className="add__btn"
              // onClick={addTitle}
              onClick={(e) => {
                // console.log(createTitleName);
                if (title.includes(createTitleName)) {
                  toast.error("Already Exists", {
                    className: "toast-message",
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    // theme: 'dark',
                    transition: Bounce,
                  });
                } else {
                  setisedt(false);
                  settitle((pr) => [...pr, createTitleName]);
                }
                setCreateTitleName("");
              }}
            >
              ADD
            </button>
          </form>
        </div>

        <div id="pro_header">
          <form
          // onSubmit={submitPriceVariant}
          >
            <div className="outr_all_header">
              <div className="all_otr">
                <div className="all_title">
                  {inputFields &&
                    inputFields.map((input, i) => {
                      return (
                        <div className="input-field-outr" key={i}>
                          {Object.keys(input)[0] !== "Save" &&
                            Object.keys(input)[0] !== "Upload Image" &&
                            Object.keys(input)[0] !== "barcode" && (
                              <span
                              // style={{ marginRight: "208px" }}
                              >
                                {Object.keys(input)[0]}
                              </span>
                            )}

                          {Object.keys(input)[0] === "Save" ||
                          Object.keys(input)[0] === "Upload Image" ||
                          Object.keys(input)[0] === "barcode" ? (
                            <button
                              onClick={(e) => {
                                if (e.target.innerHTML == "barcode") {
                                  setopendilog(true);
                                  setBarcodesrc(input?.barcode);
                                }
                              }}
                              // style={{ visibility: "hidden" }}
                              disabled={
                                Object.keys(input)[0] === "Save" ||
                                Object.keys(input)[0] === "barcode"
                                  ? showForInput
                                  : !showForInput
                              }
                              type="button"
                              className={
                                Object.keys(input)[0] === "Save" ||
                                Object.keys(input)[0] === "barcode"
                                  ? func1()
                                  : func2()
                              }

                              // onClick={() => {
                              //   if (
                              //     Object.keys(input)[0] === "Save"
                              //   ) {
                              //     saveVariantData(
                              //       Object.keys(input)[0],
                              //       "inputFields"
                              //     );
                              //   }
                              //   if (
                              //     Object.keys(input)[0] ===
                              //     "Upload Image"
                              //   ) {
                              //     uploadImageBtn(
                              //       Object.keys(input)[0],
                              //       "inputFields"
                              //     );
                              //   }
                              // }}
                            >
                              {/* {Object.keys(input)[0] === "Save"
                              ? "Save"
                              : "Upload Image"} */}
                              {Object.keys(input)[0]}
                            </button>
                          ) : (
                            <input
                              // style={{ visibility: "hidden" }}
                              placeholder="Add new"
                              required
                              name={Object.keys(input)[0]}
                              type="text"
                              defaultValue={input[Object.keys(input)[0]]}
                              onChange={(e) => chngeFirstIndexData(e, i)}
                            />
                          )}
                        </div>
                      );
                    })}
                  <button
                    // style={{ visibility: "hidden" }}
                    type="button"
                    className="add_row tab_lst_add"
                    onClick={addField}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>

                  {/* {console.log(val, "key")}

                {val[0]?.data.map((v, i) => (
                  <>
                    <div
                      // style={{ display: "block", border: "2px solid red" }}
                      key={i}
                    >
                      <span>{Object.keys(v)[0]}</span>
                      {val?.map((vv, ii) => (
                        <>
                          {vv?.data.map((vvv, iii) => {
                            if (`${Object.keys(v)[0]}` in vvv) {
                              return (
                                <>
                                  <input
                                    readOnly={true}
                                    style={{ fontSize: "25px" }}
                                    value={vvv[`${Object.keys(v)[0]}`]}
                                  />
                                  <br />
                                </>
                              );
                            } else {
                              return null;
                            }
                          })}
                        </>
                      ))}
                    </div>

                    <br />
                    <br />
                  </>
                ))} */}
                  {/* {val?.map((v, i) => (
                  <div key={i} style={{ border: "2px solid red" }}>
                    {v?.data?.map((vv, ii) => (
                      <span>
                        <span>{Object.keys(vv)[0]}</span>
                        <input
                          style={{ fontSize: "25px" }}
                          type="text"
                          name=""
                          id=""
                          value={vv[`${Object.keys(vv)[0]}`]}
                        />
                      </span>
                    ))}
                  </div>
                ))} */}
                </div>
                {arr &&
                  arr.map((obj, objIndex) => {
                    const arrFromObj = Object.keys(obj);
                    // console.log(obj, "eee");

                    return (
                      <div className="all_title">
                        {arrFromObj.map((key, keyIndex) => {
                          return (
                            <div className="input-field-outr" key={objIndex}>
                              {key === "Save" ||
                              key === "Upload Image" ||
                              key === "barcode" ? (
                                <button
                                  onClick={(e) => {
                                    if (e.target.innerHTML == "barcode") {
                                      setopendilog(true);
                                      setBarcodesrc(obj[key]);
                                      // console.log(obj[key], "eeee");
                                    }
                                  }}
                                  disabled={
                                    key === "Save" || key == "barcode"
                                      ? showForArr[objIndex]
                                      : !showForArr[objIndex]
                                  }
                                  type="button"
                                  href="javascript:void(0);"
                                  className={
                                    key === "Save" || key == "barcode"
                                      ? func3(objIndex)
                                      : func4(objIndex)
                                  }
                                  // className="edit"
                                  // onClick={() => {
                                  //   if (key === "Save") {
                                  //     saveVariantData(
                                  //       key,
                                  //       "arr",
                                  //       objIndex
                                  //     );
                                  //     setIndexOfArr(objIndex);
                                  //   }
                                  //   if (key === "Upload Image") {
                                  //     uploadImageBtn(
                                  //       key,
                                  //       "arr",
                                  //       objIndex
                                  //     );
                                  //     setIndexOfArr(objIndex);
                                  //   }
                                  // }}
                                >
                                  {/* {key === "Save" ? "Save" : "Upload Image"} */}

                                  {key}
                                </button>
                              ) : (
                                <input
                                  required
                                  type="text"
                                  name={key}
                                  defaultValue={obj[key]}
                                  key={keyIndex}
                                  // onChange={(e) =>
                                  //   changeOtherIndexData(
                                  //     e,
                                  //     objIndex
                                  //   )
                                  // }
                                />
                              )}
                            </div>
                          );
                        })}

                        <button
                          type="button"
                          className="add_row tab_lst_add"
                          onClick={addField}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </button>
                        {/* <button
                        type="button"
                        className={func5(edit)}
                        onClick={() => {
                          setedit(!edit);
                        }}
                      >
                        {edit ? "Save" : "Save"}
                      </button> */}
                      </div>
                    );
                  })}
              </div>

              <br />
            </div>
          </form>
        </div>
      </div>
      <Dialog
        // fullScreen={Fullscreen}
        open={opendilog}
        onClose={() => {
          setopendilog(false);
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <h1 id="responsive-dialog-title">{"The Barcode"}</h1>
        <h1 className="text-center">
          <img src={barcodesrc} alt="dsfssggs" />
        </h1>
      </Dialog>
    </>
  );
};

export default Variation;
